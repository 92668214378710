// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// TOP BAR
.top-bar {
    display: block;
    align-items: center;
    text-align: center;
    padding: 14px 0 13px 0;
    position: relative;

    &:after {
        .hr-after();
        bottom: 0;
    }
}

body[data-type="cart"] {
    .top-bar {
        display: none !important;
    }
}

// SHOP-HEADER
.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

.shop-header {
    position: relative;
    padding-bottom: 13px;

    &:after {
        .hr-after();
        bottom: 0;
    }
}

@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        z-index: 1000;
        transition: box-shadow .2s ease-in-out,
                    transform .2s ease-in-out;

        &.stuck {
            .shadow();
            transform: translateY(-20px);

            .shop-header-container {
                transform: translateY(10px);
            }

            .logo {
                transform: scale(0.76);
            }
        }
    }

    .shop-header-container {
        transition: transform .2s ease-in-out;
    }

    .logo,
    #search-engine,
    .header-tools-wrapper {
        transition: all .3s ease-in-out;
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 37px 0 18px 0;
    position: relative;
    z-index: 101;
}

// LOGO
.logo {
    display: flex;
    align-items: stretch;
    transform-origin: left top;
    transition: transform .2s ease-in-out;
    height: 42px;
    aspect-ratio: ~"229/42";

    img {
        .img-contain();
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 38px;
    margin-right: -5px;
}

#cart-box,
#wishlist-box,
.log-button,
.search-engine-trigger,
.tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 30px;

    &:before {
        font-family: icomoon;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        line-height: 1;
        color: @header-color;
    }

    em {
        color: @sec-color;
        font-style: normal;
        letter-spacing: 0.2em;
        line-height: 1.2;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        display: block;
        text-transform: uppercase;
    }

    &:hover em {
        color: @header-color;
    }
}

.log-button {

    &:before {
        content: "\e910";
    }
}

#wishlist-box {

    &:before {
        content: "\e911";
    }

    &[data-wishlist-count="0"]:after {
        display: none;
    }
}


#cart-box {

    &:before {
        content: "\e912";
    }

    i {
        right: 17px !important;
    }
}

#wishlist-box:after,
#cart-box i {
    font-size: 0;
    content: '';
    position: absolute;
    top: 2px;
    right: 27px;
    width: 8px;
    height: 8px;
    background-color: @main-color;
    border-radius: 50%;
}

@media (min-width: 1280px) {
    .tree-trigger,
    .tree-canvas,
    .search-engine-trigger,
    .search-engine-close {
        display: none;
    }
}

// SEARCH ENGINE
@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 420px;
        z-index: 1001;
        transform: translate(40px, -8px);
        transition: transform .2s ease-in-out;

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 44px;
            padding: 0 44px 0 10px;
            background-color: white;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: none;
            border-bottom: 1px solid @header-color;

            &::-webkit-input-placeholder {
                color: @header-color;
            }
            &::-moz-placeholder {
                color: @header-color;
            }
            &:-ms-input-placeholder {
                color: @header-color;
            }
            &:-moz-placeholder {
                color: @header-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 44px;
            height: 44px;
            color: @header-color;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: "\e919";
                font-family: icomoon;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}


// MAIN BAR
@media (min-width: 1280px) {
    .cms-rwd {
        display: none;
    }

    #main-bar {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 27px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        &:has(#tree) {

            &:has(.mainmenu) {
                
                &:after {
                    content: '';
                    background: @header-color;
                    width: 1px;
                    height: 24px;
                    order: 2;
                    flex-shrink: 0;
                    transform: translateY(-2px);
                }

                #tree {
                    order: 1;
                }

                .mainmenu {
                    order: 3;
                }
            }
        }
    }

    .mainmenu,
    #tree {
        display: flex;
        align-items: center;
        gap: 27px;

        a {
            color: @header-color;
            white-space: nowrap;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: space-between;

            &:hover {
                color: @sec-color;
            }
        }

        .tree-top > ul > li > a, // controls main bar height
        > li > a {
            padding: 16px 0;
        }
    }

    .mainmenu a {
        color: @main-color;
    }

    .tree-top,
    .tree-top > ul:not(.cms-rwd) {
        display: contents;
    }

    // TREE
    .tree-top > ul:not(.cms-rwd) {
        position: relative;

        small {
            display: none;
        }

        .parent > a:after { // arrows
            .small-icon();
            content: "\e90f";
            font-size: 16px;
        }

        .t0 { // main bar visible list
            z-index: 100;
            display: flex;
            align-items: center;

            &:hover > a:after {
                transform: scaleY(-1);
                position: relative;
                top: 1px;
            }
        }

        li { // both nested lists
            position: relative;

            ul {
                position: absolute;
                background-color: @header-color;
                min-width: 280px;
                padding: 16px 0;
                transition: all .3s ease-in-out;
                transition-delay: 0.1s;
                opacity: 0;
                visibility: hidden;

                a {
                    padding: 8px 24px;
                    color: white !important;
                    font-weight: normal;

                    &:after {
                        transform: rotate(-90deg);
                    }
                }
            }

            &:hover {

                > ul {
                    opacity: 1;
                    visibility: visible;
                    transform: none !important;
                }

                > a {
                    color: @main-color !important;
                }
            }
        }

        .t0 > ul { // first nested list
            top: 100%;
            left: -24px;
            transform: translateY(-10px); // for animation

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 8px;
                width: 100%;
                left: 0;
                top: -4px;
            }
        }

        li:not(.t0) > ul { // side nested lists
            left: calc(100%);
            top: -16px;
            transform: translateX(-10px); // for animation

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 100%;
                width: 8px;
                top: 0;
                left: -4px;
            }
        }
    }

    .tree-top > ul > li:nth-child(n+6) li > ul {
        left: initial !important;
        right: calc(100%) !important;
    }
}
// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    grid-column-gap: var(--prodGap);
    grid-row-gap: var(--prodGapVertical);
    width: 100%;

    &:empty {
        display: none;
    }

    &.detail-products {
        grid-template-columns: repeat(var(--prodSwiperCols), minmax(0, 1fr));
    }
}

.products-list .product,
.product.swiper-slide {

    &:after {
        position: absolute;
        right: calc(-0.5 * var(--prodGap));
        top: 0;
        content: '';
        height: 100%;
        width: 1px;
        background: @border-color;
    }

    &:last-child,
    &:not(.swiper-slide):nth-child(4n) {

        &:after {
            display: none;
        }
    }
}

.product.swiper-slide:after {
    right: calc(-0.5 * var(--prodGap));
}

body[data-type="detail"] .products-list .product {

    &:not(.swiper-slide):nth-child(4n):after {
        display: block;
    }

    &:not(.swiper-slide):nth-child(5n):after {
        display: none;
    }
}


.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 8px;

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-badges {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    z-index: 1;

    span {
        background-color: @sec-color;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;
        line-height: 1.2;

        &.promo-badge {
            background-color: @error-color;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;
    margin-bottom: 8px;

    img {

        &:nth-of-type(1) {
            transition: opacity .2s ease-in-out;
        }

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }
}

a.product-name {
    font-weight: normal;
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    text-align: center;

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    position: relative;
    z-index: 1;


    b, del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-weight: 700;
        color: @header-color;
    }

    del {
        color: @sec-color;
    }
}

.product-hover {
    position: absolute;
    inset: 0;
    background: rgba(24,32,41,0.7);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s opacity 0.2s ease-in-out;
    z-index: 2;
    

    .product-hover-buttons,
    .product-info {
        pointer-events: auto;
        position: absolute;
    }
}

.product-hover-buttons {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    button {
        width: 40px;
        height: 40px;
        border: 1px solid white;

        &:before {
            .small-icon();
            color: white;
            font-size: 24px;
        }

        @media (min-width: 1280px) {

            &:hover {
                border-color: @main-color;

                &:before {
                    color: @main-color;
                }
            }
        }
    }

    .product-wishlist:before {
        content: "\e911";
    }

    .product-wishlist.existing:before {
        color: @main-color;
    }

    .product-add:before {
        content: "\e912";
    }

    .product-add.disabled {
        display: none !important;
    }
}

.product-info {
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: white;
    padding: 8px 24px;
    white-space: nowrap;
    border: 1px solid white;

    &:hover {
        color: @main-color;
        border-color: @main-color;
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-name {
            text-decoration: underline;
        }

        .product-hover {
            visibility: visible;
            opacity: 1;
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
            transition-delay: .1s;
        }

        &:nth-of-type(2) {
            display: block;
            animation: .2s productImageOpacity .2s ease-in-out forwards;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 24px 0 0 0;
    margin-bottom: -40px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '/';
            margin: 0 5px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// FANCY BUTTON
.fancy-button {
    .return-button();
    display: flex;
    align-items: center;
    height: 30px;
    position: relative;
    margin: 32px auto 0 auto;

    &:before,
    &:after {
        content: '';
        width: 24px;
        height: 1px;
        background: @header-color;
        transition: all 0.2s;
        position: absolute;
    }

    &:before {
        right: calc(100% + 24px);
    }

    &:after {
        left: calc(100% + 24px);
    }

    @media (min-width: 1280px) {

        &:hover {

            &:before {
                width: 32px;
                transform: translateX(8px);
            }

            &:after {
                width: 32px;
                transform: translateX(-8px);
            }
        }
    }
}